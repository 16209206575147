import React from "react";
import styled from "styled-components/macro";
import { IconButton } from "@mui/material";
import { usePrevious, usePreviousDistinct } from "react-use";
import { devicePaddings } from "../../../tools/device";
import { stripGens } from "../../../sharedJs__generated/gens";
import { useTranslation } from "react-i18next";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import { removeDiacritics } from "../../../tools/strings";
import { useSeeHint } from "../../../components/hint/Hint";
import CardNewButton from "../card/CardNewButton";
import CardSettings from "../components/CardSettings";
import CardUndoButton from "../card/CardUndoButton";
import { useViewerQuery } from "../../../queries/viewerQuery";
import { CardValues } from "../../../components/ReduxProvider";
import { VOICEHINT } from "components/other/HintTooltip";
import { TUTORIALDONE } from "root/tutorial/steps/Verdict";
import { useKeyMy } from "hooks/useKeyMy";
import OpenAssistantButton from "../card/OpenAssistantButton";
import { compareTwoStringsUsingDiceCoefficient } from "../../../tools/strings";
import { uniq } from "lodash";
import { faKeyboard, faMicrophone } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useSpeechTapHold from "hooks/useSpeechTapHold";

const { bottom } = devicePaddings();

const Wrap = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: ${bottom + 5}px;
  margin: 0 auto;
  max-width: 640px;
  padding-bottom: 10px;
  z-index: 630;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const White = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #ffffffee;
  z-index: 620;
`;
const Transcript = styled.input<{ correct?: boolean }>`
  position: absolute;
  top: -63px;
  width: calc(100% - 100px);
  color: ${({ theme, correct }) =>
    correct ? theme.duo.color.green : correct === false ? theme.duo.color.red : theme.duo.color.primary};
  padding: 10px 0;
  text-align: center;
  font-size: 17px;
  border: none;
  pointer-events: none;
  background: none;
  &:focus {
    background: #fff;
  }
`;
const Icons = styled.div`
  svg {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.duo.color.text};
  }
`;
const SIconButton = styled(IconButton)<{
  $listening?: boolean;
  $primary?: boolean;
  $highlight?: boolean;
  $loading?: boolean;
}>`
  /* prettier-ignore */
  @keyframes scaling {
    0%, 100% { transform: scale(1.05); }
    50% { transform: scale(1.2); }
  }
  /* prettier-ignore */
  @keyframes shakeIt {
    10%, 90% { left: 2px; }
    25%, 75% { left: -3px; }
    50% { left: 3px; }
  }
  background: ${({ $primary, theme, $highlight }) =>
    $primary ? theme.duo.color.primary : $highlight ? "#fff" : "#fff5"} !important;
  color: ${({ $primary, $highlight }) => ($primary ? "white" : $highlight ? "#555" : "#aaa")};
  transform: ${({ $primary, $highlight }) => ($primary ? "scale(1.2)" : $highlight ? "scale(1)" : "scale(0.94)")};
  > svg {
    color: ${({ $listening, $primary }) => ($primary && $listening ? "white" : "inherit")};
  }
  transition: all 0.3s;
  border: ${({ theme, $highlight }) => theme.duo.border.grey + (!$highlight ? "77" : "")};
  opacity: ${({ $loading }) => ($loading ? 0.5 : 1)};
  margin: 0 5px;
  width: 52px;
  height: 52px;
  touch-action: none;
`;
const SSendIcon = styled(SendIcon)`
  color: ${({ theme }) => theme.duo.color.primary};
`;

type Props = {
  card: CardValues;
  lang: string;
  nativeLang: string;
  highlight: boolean;
  highlightKeyboard: boolean;
  onRecognition: (correct: boolean) => void;
  romanization: boolean;
};

const CardsBottom: React.FC<Props> = React.memo(
  ({ card, lang, nativeLang, onRecognition, highlight, highlightKeyboard, romanization }) => {
    const [correct, setCorrect] = React.useState<boolean>();
    const stripped = stripGens(card.front, lang);
    const [typed, setTyped] = React.useState<string>();
    const [focused, setFocused] = React.useState(false);
    const { t } = useTranslation();
    const seeHint = useSeeHint();
    const textRef = React.useRef<HTMLInputElement>(null);
    const [transcript, setTranscript] = React.useState<string | undefined>();
    const { hintWasSeen } = useViewerQuery();
    const prevCard = usePreviousDistinct(card, (prev, next) => prev?.id === next?.id);
    const prevCardState = usePrevious(card);
    const resetTranscript = () => setTranscript(undefined);

    const testFuzzyMatch = (input: string, expected: string, fuzzyMatchingThreshold: number) => {
      const removeSpecialsAndTrim = (text: string) =>
        removeDiacritics(text)
          .replace(/[&/\\#,+()!$~%.'":*?<>{}]/g, "")
          .replace(/  +/g, " ")
          .trim();

      const similarFactor = compareTwoStringsUsingDiceCoefficient(
        removeSpecialsAndTrim(expected),
        removeSpecialsAndTrim(input)
      );
      return similarFactor >= fuzzyMatchingThreshold;
    };

    const {
      listening,
      transcribing,
      supported: canSpeak,
      startListening: _startListening,
      stopListening,
      cancelListening,
      handlers
    } = useSpeechTapHold({
      onTranscription: (text) => {
        setTranscript(text);
        const newCorrect = uniq([card.front, stripped]).some((s) => testFuzzyMatch(text, s, 0.6));
        setCorrect(newCorrect);
        onRecognition(newCorrect);
      },
      options: {
        languages: [lang],
        stopAfterMs: 10000
      }
    });

    React.useEffect(() => {
      if (prevCardState === card) return;
      if (listening) cancelListening();
      resetTranscript();
      setTyped(undefined);
      setCorrect(undefined);
    }, [card, prevCardState, cancelListening, listening]);

    React.useEffect(() => {
      if (!listening && transcript && correct === undefined) {
        onRecognition(false);
        setCorrect(false);
      }
    }, [listening, transcript, correct, onRecognition]);

    const startListening = React.useCallback(() => {
      seeHint(VOICEHINT);
      setTyped(undefined);
      setCorrect(undefined);
      setTimeout(() => _startListening());
    }, [seeHint, _startListening]);

    const startTyping = React.useCallback(
      (e) => {
        e.preventDefault();
        seeHint(VOICEHINT);
        if (listening) cancelListening();
        resetTranscript();
        setCorrect(undefined);
        if (typed === undefined) {
          setTimeout(() => setTyped(""));
        } else {
          textRef?.current?.select();
        }
      },
      [seeHint, listening, cancelListening, typed]
    );

    const handleTyped = () => {
      if (typed) {
        const tpd = removeDiacritics(typed || "")
          .toLowerCase()
          .trim();
        const front = removeDiacritics(card.front).toLowerCase().trim();
        const back = removeDiacritics(card.back).toLowerCase().trim();
        const newCorrect =
          front === tpd ||
          stripGens(front, lang) === stripGens(tpd, lang) ||
          (!card.flipped && (back === tpd || stripGens(back, nativeLang) === stripGens(tpd, nativeLang)));
        setCorrect(newCorrect);
        onRecognition(newCorrect);
      } else {
        setTyped(undefined);
      }
      setFocused(false);
    };

    useKeyMy((e) => e.code === "KeyK", startTyping, undefined, [startTyping]);
    useKeyMy((e) => e.code === "KeyM", listening ? stopListening : startListening, undefined, [listening]);

    return (
      <>
        {focused && <White />}
        <Wrap>
          {(transcript || listening) && (
            <Transcript
              readOnly
              correct={correct}
              value={transcript || (listening ? String(t("Start speaking...")) : "")}
            />
          )}
          {typed !== undefined && (
            <Transcript
              correct={correct}
              value={typed}
              onChange={(e) => setTyped(e.target.value)}
              onFocus={() => setFocused(true)}
              onBlur={() => {
                setTimeout(() => {
                  setFocused(false); // delayed to let send-click to be first
                  typed === "" && setTyped(undefined);
                }, 100);
              }}
              style={{ pointerEvents: focused ? "auto" : "none" }}
              className="nofilter"
              autoFocus
              ref={textRef}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  textRef?.current?.blur();
                  handleTyped();
                }
                e.stopPropagation();
              }}
            />
          )}
          <Icons>
            {typed === undefined || !focused ? (
              <SIconButton title="k" $highlight={highlight || highlightKeyboard} onClick={startTyping}>
                <FontAwesomeIcon icon={faKeyboard} />
              </SIconButton>
            ) : (
              <SIconButton onClick={handleTyped}>
                <SSendIcon />
              </SIconButton>
            )}

            {typed !== undefined && focused ? (
              <SIconButton onClick={() => setTyped(undefined)}>
                <CloseIcon />
              </SIconButton>
            ) : (
              canSpeak && (
                <SIconButton
                  {...handlers}
                  title="m"
                  $highlight={highlight}
                  $primary={listening}
                  $listening
                  $loading={transcribing}
                >
                  <FontAwesomeIcon icon={faMicrophone} />
                </SIconButton>
              )
            )}
          </Icons>
          {hintWasSeen(TUTORIALDONE) && (
            <Icons>
              <OpenAssistantButton card={card} />
              <CardNewButton />
              <CardSettings lang={lang} romanization={romanization} />
              <CardUndoButton prevCard={prevCard as any} />
            </Icons>
          )}
        </Wrap>
      </>
    );
  }
);

export default CardsBottom;

/*  USAGE OF MEDIARECORDER

      <div
        style={{ float: "right" }}
        onClick={() => {
          if (navigator.mediaDevices && (window as any).MediaRecorder) {
            let chunks: any[] = [];
            navigator.mediaDevices.getUserMedia({ audio: true }).then(function (stream) {
              var mediaRecorder = new (window as any).MediaRecorder(stream);
              mediaRecorder.onstop = function (e) {
                var blob = new Blob(chunks, { type: "audio/ogg; codecs=opus" });
                const mysound = new Audio(URL.createObjectURL(blob)); ///"data:audio/wav;base64," + data.tts?.url);
                mysound.play();
                chunks = [];
              };
              mediaRecorder.ondataavailable = function (e: any) {
                chunks.push(e.data);
              };
              mediaRecorder.start();
              setTimeout(() => mediaRecorder.stop(), 3000);
            });
          } else {
          }
        }}
      >
        RECORD
      </div> */
