/**
 * @generated SignedSource<<004e81d9a9b7349a67cbf83dc7d4777f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EmailAuthGetEmailInfoMutation$variables = {
  email: string;
};
export type EmailAuthGetEmailInfoMutation$data = {
  readonly getEmailInfo: {
    readonly apple: boolean;
    readonly exists: boolean;
    readonly google: boolean;
    readonly loginLinkSent: boolean;
    readonly password: boolean;
  };
};
export type EmailAuthGetEmailInfoMutation = {
  response: EmailAuthGetEmailInfoMutation$data;
  variables: EmailAuthGetEmailInfoMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      }
    ],
    "concreteType": "GetEmailInfoReturnType",
    "kind": "LinkedField",
    "name": "getEmailInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "exists",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "password",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "google",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "apple",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "loginLinkSent",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmailAuthGetEmailInfoMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmailAuthGetEmailInfoMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "750f8510a1bf5e2fefc9f676ace685d3",
    "id": null,
    "metadata": {},
    "name": "EmailAuthGetEmailInfoMutation",
    "operationKind": "mutation",
    "text": "mutation EmailAuthGetEmailInfoMutation(\n  $email: String!\n) {\n  getEmailInfo(email: $email) {\n    exists\n    password\n    google\n    apple\n    loginLinkSent\n  }\n}\n"
  }
};
})();

(node as any).hash = "7feff47718444bd983ebdd6f44bb95a5";

export default node;
