import React from "react";
import { useTranslation } from "react-i18next";
import { graphql } from "babel-plugin-relay/macro";
import { LoginGuestMutation } from "./__generated__/LoginGuestMutation.graphql";
import { useMutation } from "relay-hooks";
import { useSetState, useGetState } from "components/ReduxProvider";
import { dispatchEvent, EVENT } from "tools/events";
import { UserAction } from "components/initializers/UserActionsLogger";
import { Button, ButtonProps } from "@mui/material";
import { isWebPpcCampaign } from "tools/device";

const mutation = graphql`
  mutation LoginGuestMutation {
    loginGuest {
      token
    }
  }
`;

type Props = {
  onClick?: ButtonProps["onClick"];
};

const LoginGuest = (props: Props) => {
  const { t } = useTranslation();
  const [submitting, setSubmitting] = React.useState(false);
  const setToken = useSetState("token");
  const abtest_loginButton = useGetState("abtest_loginButton") === "on";

  const [loginGuest] = useMutation<LoginGuestMutation>(mutation, {
    onCompleted: ({ loginGuest }) => {
      if (loginGuest) {
        setToken(loginGuest.token);
        dispatchEvent(EVENT.logUserAction, { action: UserAction.userAuthorized, detail: { type: "guest" } });
      } else {
        setSubmitting(false);
        dispatchEvent(EVENT.logUserAction, { action: UserAction.userAuthorizedFailed, detail: { type: "guest" } });
      }
    },
    onError: () => setSubmitting(false)
  });

  const handleGuest = (e) => {
    e.preventDefault();
    loginGuest({ variables: {} });
    setSubmitting(true);
    props.onClick?.(e);
  };

  return (
    <Button
      variant="contained"
      color="primary"
      size="large"
      fullWidth
      onClick={handleGuest}
      disabled={submitting}
      id="guest-login-debug-id"
    >
      {t(!abtest_loginButton || isWebPpcCampaign() ? "Continue" : "Create your account")}
    </Button>
  );
};

export default LoginGuest;
