import { useGetState, useSetState } from "components/ReduxProvider";
import { useViewerQuery } from "queries/viewerQuery";

const getTodayString = () => new Date().toISOString().substring(0, 10);

const getGrammarUsageForToday = (grammarUsage: { dateISO: string; views: number } | undefined) => {
  const todayString = getTodayString();
  return grammarUsage?.dateISO === todayString ? grammarUsage.views : 0;
};

const DAILY_GRAMMAR_QUOTA = {
  NEW_USER: 10,
  EXISTING_USER: 5
};

export const useGrammarQuotaExceeded = () => {
  const grammarUsage = useGetState("grammarUsage");
  const { viewer } = useViewerQuery();
  const todayUsage = getGrammarUsageForToday(grammarUsage);

  if (viewer?.subscription) return false;

  const quota = viewer?.createdBeforeDays === 0 ? DAILY_GRAMMAR_QUOTA.NEW_USER : DAILY_GRAMMAR_QUOTA.EXISTING_USER;
  return todayUsage > quota;
};

export const useIncrementGrammarQuota = () => {
  const grammarUsage = useGetState("grammarUsage");
  const setGrammarUsage = useSetState("grammarUsage");

  return (increment: number) => {
    const todayUsage = getGrammarUsageForToday(grammarUsage);
    setGrammarUsage({
      dateISO: getTodayString(),
      views: todayUsage + increment
    });
  };
};
