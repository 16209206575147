import React, { useEffect, useCallback } from "react";
import { ReassuranceSlide } from "./ReassuranceSlide";
import { Trans } from "react-i18next";
import { Box, Button, Stack } from "@mui/material";
import { BrandTitle } from "../../../components/other/BrandTitle";
import LoginHeader from "../LoginHeader";
import { Content, Page, IndicatorButton, Indicator } from "./ReassuranceScreen.styles";
import LoginGuest from "../LoginGuest";
import { Carousel } from "react-responsive-carousel";
import { useDBLog } from "../../../hooks/useDBLog";
import { isWebPpcCampaign } from "tools/device";
import { useGetState } from "components/ReduxProvider";

type Props = {
  onLogin: () => void;
};

export function ReassuranceScreen(props: Props) {
  const customRenderItem = (item, props) => <item.type {...item.props} {...props} />;
  const dblog = useDBLog();
  const deviceId = useGetState("deviceId");

  const log = useCallback(
    (text: string) => {
      if (deviceId) {
        dblog("Reassurance", text, { deviceId }, 30);
      }
    },
    [dblog, deviceId]
  );

  useEffect(() => {
    log("reassurance loaded");
  }, [log]);

  return (
    <Page>
      <Content>
        {isWebPpcCampaign() ? <div /> : <LoginHeader />}
        <Box sx={{ textAlign: "center" }}>
          <BrandTitle>DuoCards</BrandTitle>
        </Box>
        <Carousel
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          autoPlay={false}
          infiniteLoop
          onSwipeEnd={() => {
            log("carousel swiped");
            return true;
          }}
          renderItem={customRenderItem}
          renderIndicator={(onClickHandler, isSelected, index, label) => {
            return (
              <Indicator
                onClick={(event) => {
                  if (!isSelected) {
                    log("carousel clicked");
                    onClickHandler(event);
                  }
                }}
                onKeyDown={isSelected ? undefined : onClickHandler}
                key={index}
                role="button"
                tabIndex={0}
                title={`${label} ${index + 1}`}
                aria-label={`${label} ${index + 1}`}
              >
                <IndicatorButton $active={isSelected} />
              </Indicator>
            );
          }}
        >
          <ReassuranceSlide
            key="1"
            url="https://imgs.duocards.com/rive/reassurance-slide-5.riv"
            legend={<Trans>Effective vocabulary learning</Trans>}
          />
          <ReassuranceSlide
            key="3"
            url="https://imgs.duocards.com/rive/reassurance-slide-3.riv"
            legend={
              <Trans>
                Swipe and let the <strong>algorithm do the rest</strong>
              </Trans>
            }
          />
          <ReassuranceSlide
            key="2"
            url="https://imgs.duocards.com/rive/reassurance-slide-2.riv"
            legend={<Trans>Add words from anywhere, never forget them</Trans>}
          />
          <ReassuranceSlide
            key="4"
            url="https://imgs.duocards.com/rive/reassurance-slide-4.riv"
            legend={
              <Trans>
                Practice vocabulary with <strong>Memo, your AI learning buddy!</strong>
              </Trans>
            }
          />
        </Carousel>
        <Box sx={{ textAlign: "center" }}>
          <Stack direction="column" spacing={2}>
            {!isWebPpcCampaign() && (
              <Button variant="outlined" color="primary" size="large" fullWidth onClick={props.onLogin}>
                <Trans>I already have an account</Trans>
              </Button>
            )}
            <LoginGuest />
          </Stack>
        </Box>
      </Content>
    </Page>
  );
}
