import { useRef, useEffect } from "react";
import useSpeechToText from "./useSpeechToText";
import useTapOrHold from "./useTapOrHold";
import { useGetState } from "components/ReduxProvider";
import { usePrevious } from "react-use";

type SpeechTapHoldOptions = {
  languages?: string[];
  stopAfterMs?: number;
};

interface UseSpeechTapHoldOptions {
  onTranscription: (text: string) => void;
  options?: SpeechTapHoldOptions;
}

export default function useSpeechTapHold({ onTranscription, options }: UseSpeechTapHoldOptions) {
  const globalRecording = useGetState("isAudioRecording");
  const ref = useRef<HTMLButtonElement>(null);

  const { languages, stopAfterMs } = options || {};

  const { transcribing, listening, supported, startListening, stopListening, cancelListening } = useSpeechToText(
    onTranscription,
    {
      languages,
      listeningOptions: {
        stopAfterMs,
        sourceElementRef: ref
      }
    }
  );
  const prevListening = usePrevious(listening);

  const otherRecording = globalRecording ? !listening : false;

  const { handlers, resetPressState } = useTapOrHold({
    startAction: startListening,
    endAction: stopListening,
    disabled: otherRecording,
    holdThresholdMs: 1000 // 1 second, for some users 600ms is too short, and in this case is OK to have this higher threshold
  });

  // listening can be closed externally, so we need to reset the press state
  useEffect(() => {
    if (!listening && prevListening) {
      resetPressState();
    }
  }, [listening, prevListening, resetPressState]);

  return {
    // states
    listening,
    transcribing,
    supported,
    globalRecording,
    otherRecording,

    // methods
    startListening,
    stopListening,
    cancelListening,
    // pass these handlers down to your UI
    handlers: {
      ref,
      ...handlers
    }
  };
}
