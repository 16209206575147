import { Howl } from "howler";

type HowlCacheEntry = {
  howl: Howl;
  lastAccessed: number;
};

type HowlerCacheOptions = {
  maxSize?: number; // Maximum items before eviction
  cleanupFactor?: number; // Fraction to remove when over limit
  maxTimeMs?: number; // Time until entry considered stale
};

export class HowlerCache {
  private cache: Map<string, HowlCacheEntry>;
  private maxSize: number;
  private cleanupFactor: number;
  private maxTimeMs: number;

  constructor(options: HowlerCacheOptions = {}) {
    this.cache = new Map();
    this.maxSize = options.maxSize ?? 50;
    this.cleanupFactor = options.cleanupFactor ?? 0.5;
    this.maxTimeMs = options.maxTimeMs ?? 0; // 0 disables time-based cleanup
  }

  add(key: string, howl: Howl): void {
    this.cache.set(key, {
      howl,
      lastAccessed: Date.now()
    });

    this.maintainCacheConstraints();
  }

  get(key: string): Howl | undefined {
    const entry = this.cache.get(key);
    if (entry) {
      entry.lastAccessed = Date.now();
      return entry.howl;
    }
    return undefined;
  }

  // Enforces both size and time constraints on the cache
  private maintainCacheConstraints(): void {
    this.removeStaleEntries();

    if (this.cache.size > this.maxSize) {
      this.removeOldestEntries();
    }
  }

  private removeStaleEntries(): void {
    if (this.maxTimeMs <= 0) return;

    const now = Date.now();
    Array.from(this.cache).forEach(([key, entry]) => {
      if (now - entry.lastAccessed > this.maxTimeMs) {
        entry.howl.unload();
        this.cache.delete(key);
      }
    });
  }

  // Removes least recently used entries when cache exceeds maxSize
  private removeOldestEntries(): void {
    const entriesToRemove = Math.ceil(this.maxSize * this.cleanupFactor);
    const sortedByAge = Array.from(this.cache.entries()).sort((a, b) => a[1].lastAccessed - b[1].lastAccessed);

    for (let i = 0; i < entriesToRemove && i < sortedByAge.length; i++) {
      const [key, entry] = sortedByAge[i];
      entry.howl.unload();
      this.cache.delete(key);
    }
  }
}

export const howlerCache = new HowlerCache({
  maxSize: 30,
  cleanupFactor: 0.5,
  maxTimeMs: 5 * 60 * 1000 // 5 minutes
});
