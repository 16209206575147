import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import { generateGrammarMutation } from "./__generated__/generateGrammarMutation.graphql";
import { snackbar } from "tools/events";

export const generateGrammarQL = graphql`
  mutation generateGrammarMutation($text: String!, $fromLang: String!, $toLang: String!) {
    generateGrammar(text: $text, fromLang: $fromLang, toLang: $toLang) {
      text
      examples
      grammar {
        id
        lang
        grammar
      }
    }
  }
`;

export const useGenerateGrammarMutation = () => {
  const [mutate, { loading, data }] = useMutation<generateGrammarMutation>(generateGrammarQL, {
    onError: () => {
      snackbar("Memo did not find the grammar. Please try again.", { severity: "error" });
    }
  });

  return { generateGrammar: mutate, loading, data: data?.generateGrammar };
};
