import React from "react";
import { useSaleExpiration, useSubscriptions } from "../../root/profile/subscription/subscriptionHooks";
import { useStoreIds } from "../../root/profile/subscription/subscriptionIds";
import { useDBLog } from "../../hooks/useDBLog";
import { useViewerQuery } from "../../queries/viewerQuery";
import { useDebounce } from "react-use";
import { isSubFor3months, isSubForFamily, isSubForYear } from "sharedJs__generated/functions";

const LogWrongPrice = () => {
  const { viewer } = useViewerQuery();
  const { storeIdYear, storeIdFamily, storeIdMonth } = useStoreIds();
  const { saleId } = useSaleExpiration();
  const dblog = useDBLog();
  const reportedRef = React.useRef<boolean>(false);

  const { subscriptions: subscriptionsOrig } = useSubscriptions();
  const [subscriptions, setSubscriptions] = React.useState<typeof subscriptionsOrig>();
  useDebounce(() => setSubscriptions(subscriptionsOrig), 3000); // to be sure, we will check the final state of subscriptions

  React.useEffect(() => {
    if (!subscriptions || reportedRef.current || !viewer || saleId || !isSubForYear(storeIdYear)) return;

    reportedRef.current = true;

    const threeMonthPrice = subscriptions[storeIdMonth]?.priceMicros;
    const yearPrice = subscriptions[storeIdYear]?.priceMicros;
    const familyPrice = subscriptions[storeIdFamily]?.priceMicros;

    // year price should be 40% discount from family => family * 0.6
    if (isSubForFamily(storeIdFamily) && (yearPrice < familyPrice * 0.48 || yearPrice > familyPrice * 0.72)) {
      dblog("subscription prices check", "year price is not within the ranges of family price", {
        year: subscriptions[storeIdYear],
        family: subscriptions[storeIdFamily],
        timezone: viewer.timezone
      });
    }
    // 3 months price should 50% of yearly, so yearly * 0.5
    if (isSubFor3months(storeIdMonth) && (threeMonthPrice < yearPrice * 0.38 || threeMonthPrice > yearPrice * 0.62)) {
      dblog("subscription prices check", "three months price is not within the ranges of year price", {
        year: subscriptions[storeIdYear],
        month: subscriptions[storeIdMonth],
        timezone: viewer.timezone
      });
    }
  }, [subscriptions, storeIdYear, storeIdFamily, storeIdMonth, dblog, viewer, saleId]);

  return null;
};

export default LogWrongPrice;
