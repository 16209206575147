import React from "react";
import { Box } from "@mui/material";
import { Trans } from "react-i18next";
import styled from "styled-components/macro";

const Title = styled.div`
  font-weight: bold;
  font-size: 0.9rem;
`;

type Props = {
  text: string;
  description: string;
};
export default function NotificationOption({ text, description }: Props) {
  return (
    <React.Fragment>
      <Box>
        <Title>
          <Trans>{text}</Trans>
        </Title>
      </Box>
      <Box sx={{ fontSize: "0.7rem" }}>
        <Trans>{description}</Trans>
      </Box>
    </React.Fragment>
  );
}
