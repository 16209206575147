import React from "react";
import { useLocalStorage } from "react-use";

const Smartlook: React.FC<{}> = () => {
  // monitor only 1 from 500 new users to save limit for usecases when we would like to monitor specific use-cases
  const isChosen = Math.random() < 1 / 500;
  const newUser = !localStorage.getItem("reduxState");

  let [smartlookOn, setValue] = useLocalStorage<number | false | undefined>("smartlookOn");
  if (smartlookOn === undefined) {
    smartlookOn = newUser && isChosen && Date.now();
    setValue(smartlookOn);
  }

  React.useEffect(() => {
    const sixDaysInMS = 6 * 24 * 3600 * 1000;

    if (!smartlookOn || smartlookOn < Date.now() - sixDaysInMS) return;

    try {
      (window as any).smartlook ||
        (function (d) {
          var o: any = ((window as any).smartlook = function () {
              o.api.push(arguments);
            }),
            h = d.getElementsByTagName("head")[0];
          var c = d.createElement("script");
          o.api = [];
          c.async = true;
          c.type = "text/javascript";
          c.src = "https://web-sdk.smartlook.com/recorder.js";
          h.appendChild(c);
        })(document);
      (window as any).smartlook("init", "11e51742996f09fbf384f979383c701d0ee3bab6", { region: "eu" });
    } catch (e) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default Smartlook;
