import React from "react";
import styled from "styled-components/macro";
import { IconButton, Paper } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import AIGrammar from "components/GrammarDictionary/AIGrammar";
import CloseIcon from "@mui/icons-material/Close";
import GrammarQuota from "components/GrammarDictionary/GrammarQuota";
import { useEffectOnce } from "react-use";
import { Grammar } from "dexieDB/dexie";
import Loader from "components/other/Loader";
import { Trans } from "react-i18next";
import { useGrammarQuotaExceeded, useIncrementGrammarQuota } from "./grammarQuotaExceeded";
import { useGrammarLoadingMessages } from "./useGrammarLoadingMessages";

export type GrammarTabId = "ai";

const SPaper = styled(Paper)`
  overflow: auto;
  position: relative;
  width: 100%;
  margin 10px 
  max-width: 800px !important;
  background: white !important;
`;
const SIconButton = styled(IconButton)`
  position: absolute;
  right: 7px;
  top: 12px;
`;
const STabPanel = styled(TabPanel)`
  padding-top: 15px;
`;
const LoaderWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 120px;
  width: 100%;
  padding: 30px 10px;
`;

const LoaderTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  padding: 0px 10px 20px;
`;

type Props = {
  loading?: boolean;
  grammar?: readonly Grammar[] | null;
  onClose?: VoidFunction;
  className?: string;
};

const GrammarDictionary: React.FC<Props> = ({ grammar, onClose, className, loading }) => {
  const grammarQuotaExceeded = useGrammarQuotaExceeded();
  const incrementGrammarQuota = useIncrementGrammarQuota();
  const { loadingMessage } = useGrammarLoadingMessages(loading);

  useEffectOnce(() => {
    incrementGrammarQuota(1);
  });

  return (
    <SPaper className={className} onClick={(e) => e.stopPropagation()}>
      {loading ? (
        <LoaderWrap>
          <LoaderTitle>
            <Trans>{loadingMessage}</Trans>
          </LoaderTitle>
          <Loader />
        </LoaderWrap>
      ) : (
        <TabContext value={"ai"}>
          {grammarQuotaExceeded ? (
            <STabPanel value="ai">
              <GrammarQuota />
            </STabPanel>
          ) : (
            grammar &&
            grammar?.length > 0 && (
              <STabPanel value="ai">
                <AIGrammar moveLangTab={!!onClose} grammar={grammar} />
              </STabPanel>
            )
          )}
        </TabContext>
      )}

      {onClose && (
        <SIconButton onClick={onClose}>
          <CloseIcon />
        </SIconButton>
      )}
    </SPaper>
  );
};

export default GrammarDictionary;
