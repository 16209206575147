export const TRYSHARINGWORDS = "butterfly\nanxious\nacumen";

export const SUBSKICKHINT = "subsKick";

export const FRIENDSREWARD = "friendsReward";
export const FAMEMBERSTOREID = "famember";
export const MANUALSTOREID = "manual";

export const MAXKNOWNCOUNT = 7;
export const MAX_CARDS_IN_SINGLE_SET = 1200;

export const SUBDBLOG = "subscription";
export const NOTIFICATIONDBLOG = "notifications";

// shared hints
export const TUTORIALDONE_SHARED_HINT = "noCards3"; // it's really "3" historically
export const SHARE_AND_CHROME_QUEST = "chromeextx";
export const TUTORIAL_SWIPING_DONE = "tutorialSwipingDone";
export const STREAK_FREEZE_QUEST = "streakFreeze";
export const STREAK_FREEZE_EARN_QUEST = "streakFreezeEarn";
export const MOTIVATIONHINT = "motivation";

export const CURRENT_DECK_ID_FLAG = "myCurrentDeckId";
export const YEAR_WRAPUP_FLAG = "myYearWrapup";

export const NEW_YEAR_START_DATE = new Date("2025-01-01");
export const NEW_YEAR_END_DATE = new Date("2025-01-07");

export const WRAPUP_START_DATE = new Date("2024-12-23");
export const WRAPUP_END_DATE = new Date("2024-12-31");

export const GOAL_FLAG_NAME = "myGoal";

export const STREAK_XPS = 5;
export const STREAK_FREEZE_XPS = 50;
export const STREAK_FREEZE_LIMIT = 1;
export const STREAK_FREEZE_LIMIT_SUBSCRIBER = 2;

export const MINIMUM_WRAPUP_USERTOTAL_XP = 50;
export const MINIMUM_DAYS_USER_CREATED_BEFORE_WRAPUP = 10;

export const CONTENT_TEST_FLAG = "myContentTest";
export const contentTestValue = (testId: string, on: boolean) => `${testId}:${on ? "on" : "off"}`;
export const MAX_SUBSCRIBER_AI_MESSAGES = 3_000;
export const MAX_FREE_AI_MESSAGES = 10;
export const AFFILIATE_MINIMUM_PAYMENT = 200 as const;

export const MULTILINGUAL_LANG_CODE = "xx"; // we will use for multilingual voices, is safe to use "xx" based on https://en.wikipedia.org/wiki/List_of_ISO_639_language_codes

export const DEFAULT_LANG = "en";

export enum CardStateEnum {
  all = "all",
  toLearn = "toLearn",
  known = "known",
  learned = "learned"
}

export enum NotificationEnum {
  none = 0,
  easy = 1,
  normal = 2,
  hard = 3
}

export enum XpsFreezeEnum {
  frozen = "frozen",
  frozenNotified = "frozenNotified"
}

export enum SourcesSortEnum {
  popularity = "popularity",
  beginnerFirst = "beginnerFirst",
  easyFirst = "easyFirst",
  moderateFirst = "moderateFirst",
  hardFirst = "hardFirst"
}

export enum DifficultyEnum {
  beginner = "beginner",
  easy = "easy",
  moderate = "moderate",
  hard = "hard"
}

export enum LoginMethodEnum {
  email = "email",
  google = "google",
  apple = "apple"
}

export enum Platform {
  android = "android",
  ios = "ios",
  browser = "browser",
  lemon = "lemon"
}

export enum SALE {
  blackFriday = "blackFriday",
  christmasFamily = "christmasFamily",
  wrapup = "wrapup",
  newYear = "newYear"
}

export enum NOTIFICATION {
  noCards = "noCards",
  sale = "sale",
  blackFridayNotif = "blackFridayNotif",
  christmasFamilyNotif = "christmasFamilyNotif",
  wrapupNotif = "wrapupNotif",
  newYearNotif = "newYearNotif",
  expired = "expired"
}

export type AIChatModelNames =
  | "o1-preview"
  | "gpt-4o-mini"
  | "gpt-4o"
  | "anthropic/claude-3.5-sonnet"
  | "anthropic/claude-3-opus"
  | "google/gemini-flash-1.5"; // models containing "/" will be routed through openrouter, otherwise through openai
export type AIRequestTemplates = "assistant suggestions" | "translate" | "context translate";

export type SuggestionsTranslations = { originalText: string; translatedText: string }[];

// https://stackoverflow.com/questions/57858862/ensure-that-generic-type-only-has-primitive-properties-in-typescript
export type Serializable<T> = T extends string | number | boolean | null
  ? T
  : T extends Function // eslint-disable-line @typescript-eslint/ban-types
    ? never
    : T extends object
      ? { [K in keyof T]: Serializable<T[K]> }
      : never;
