import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useOnboardingStepsData } from "./onboardingStepsData";
import { ONBOARDING } from "../../RootRouter.config";
import { StepScreenId } from "./onboardingTypes";

const ONBOARDING_HASH_PREFIX = "#onboarding-";

export const getStepHash = (step: StepScreenId) => `${ONBOARDING_HASH_PREFIX}${step}`;
export const getStepNameFromHash = (hash: string) => hash.replace(ONBOARDING_HASH_PREFIX, "");

type OnboardingRedirectProps = {
  screenId: StepScreenId;
};

const OnboardingRedirect: React.FC<OnboardingRedirectProps> = ({ screenId }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const onboardingStepsData = useOnboardingStepsData();

  React.useEffect(() => {
    const step = onboardingStepsData.find((_step) => _step.screenId === screenId);
    if (!step) return;
    const hash = getStepHash(step.screenId);
    navigate(ONBOARDING.url() + hash, { replace: true });
  }, [navigate, screenId, location, onboardingStepsData]);

  return null;
};

export default OnboardingRedirect;
