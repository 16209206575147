import React from "react";
import styled from "styled-components/macro";
import { Form, Formik } from "formik";
import { HeaderLayout } from "../../components/header/Header";
import { FTextField } from "../../styled/TextField";
import { Trans, useTranslation } from "react-i18next";
import { Button, IconButton } from "@mui/material";
import { useViewerQuery, useViewerXps } from "../../queries/viewerQuery";
import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import { UserEditMutation } from "./__generated__/UserEditMutation.graphql";
import { useNavigate } from "react-router";
import Avatar from "./Avatar";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import { PROFILE, VERIFY } from "../RootRouter.config";

export const UserEditQL = graphql`
  mutation UserEditMutation($email: String!, $name: String, $bio: String) {
    editUser(email: $email, name: $name, bio: $bio) {
      name
      email
      bio
      emailVerified
    }
  }
`;

const SHeaderLayout = styled(HeaderLayout)`
  padding: 20px;
`;

type Props = {};

const UserEdit: React.FC<Props> = () => {
  const [editUser] = useMutation<UserEditMutation>(UserEditQL);
  const { t } = useTranslation();
  const { viewer } = useViewerQuery();
  const { xps } = useViewerXps();
  const navigate = useNavigate();

  if (!viewer) return null;

  const handleSubmit = (variables: UserEditMutation["variables"]) => {
    editUser({
      variables: { ...variables, bio: variables.bio || null },
      onCompleted: ({ editUser }) => navigate(!editUser.emailVerified ? VERIFY.url() : PROFILE.url())
    });
  };

  return (
    <SHeaderLayout
      title={<Avatar user={{ ...viewer, xps: { total: xps?.total } }} />}
      right={
        <IconButton onClick={() => navigate(`/author/${viewer.name}`)}>
          <AssignmentIndIcon />
        </IconButton>
      }
    >
      <Formik
        initialValues={{
          name: viewer?.name as string,
          email: viewer?.email as string,
          bio: viewer?.bio || ""
        }}
        onSubmit={handleSubmit}
      >
        {({ dirty }) => (
          <Form>
            <FTextField name="name" label={t("Name or Nickname")} required />
            <FTextField name="email" type="email" label="Email" required />
            <FTextField
              name="bio"
              label="Bio"
              multiline
              inputProps={{
                maxLength: 400
              }}
            />
            <Button variant="contained" color="primary" type="submit" fullWidth disabled={!dirty}>
              <Trans>Save</Trans>
            </Button>
          </Form>
        )}
      </Formik>
    </SHeaderLayout>
  );
};

export default UserEdit;
