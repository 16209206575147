export const langs: {
  [key: string]: {
    label: string;
    country?: string;
    native: string;
    tts?: boolean;
    flag?: string;
    ttsCode?: string;
    ytLang?: string;
    flagPng?: string;
    aiSupport?: boolean;
    multiLangTts?: boolean; // multi lang tts is supported for this language -> https://platform.openai.com/docs/guides/text-to-speech/supported-languages
  };
} = {
  af: { label: "Afrikaans", country: "za", native: "Afrikaans", multiLangTts: true },
  sq: { label: "Albanian", country: "al", native: "shqiptar" },
  am: { label: "Amharic", country: "et", native: "አማርኛ" },
  ar: { label: "Arabic", native: "عربى", country: "ae", aiSupport: true, multiLangTts: true },
  hy: { label: "Armenian", country: "am", native: "հայերեն", multiLangTts: true },
  az: { label: "Azerbaijani", native: "Azərbaycan", multiLangTts: true },
  eu: { label: "Basque", flag: "🏴", native: "Euskal" },
  be: { label: "Belarusian", tts: false, country: "by", native: "Беларус", aiSupport: true, multiLangTts: true },
  bn: { label: "Bengali", country: "bd", native: "বাংলা" },
  bs: { label: "Bosnian", country: "ba", native: "Bosanski", aiSupport: true, multiLangTts: true },
  bg: { label: "Bulgarian", native: "български", aiSupport: true, multiLangTts: true },
  ca: { label: "Catalan", flag: "🇦🇩", native: "Català", multiLangTts: true },
  ceb: { label: "Cebuano", tts: false, country: "ph", native: "Cebuano" },
  zh: { label: "Chinese", country: "cn", native: "中文 Mandarin", aiSupport: true, multiLangTts: true },
  co: { label: "Corsican", tts: false, country: "fr", native: "Corsu" },
  hr: { label: "Croatian", native: "Hrvatski", aiSupport: true, multiLangTts: true },
  cs: { label: "Czech", country: "cz", native: "čeština", aiSupport: true, multiLangTts: true },
  da: { label: "Danish", country: "dk", native: "dansk", aiSupport: true, multiLangTts: true },
  nl: { label: "Dutch", native: "Nederlands", aiSupport: true, multiLangTts: true },
  en: { label: "English", country: "gb", native: "English", aiSupport: true, multiLangTts: true },
  eo: { label: "Esperanto", tts: false, flag: "📗", native: "Esperanto" },
  et: { label: "Estonian", country: "ee", native: "Eestlane", multiLangTts: true },
  fi: { label: "Finnish", native: "Suomalainen", aiSupport: true, multiLangTts: true },
  fr: { label: "French", native: "français", aiSupport: true, multiLangTts: true },
  fy: { label: "Frisian", tts: false, flag: "🦢", native: "Frysk" },
  gl: { label: "Galician", country: "es", native: "Galego", multiLangTts: true },
  ka: { label: "Georgian", country: "ge", native: "ქართული" },
  de: { label: "German", native: "Deutsche", aiSupport: true, multiLangTts: true },
  el: { label: "Greek", country: "gr", native: "Ελληνικά", aiSupport: true, multiLangTts: true },
  gu: { label: "Gujarati", country: "in", native: "ગુજરાતી" },
  ht: { label: "Haitian Creole", tts: false, native: "Kreyòl Ayisyen" },
  ha: { label: "Hausa", tts: false, country: "ne", native: "Hausa" },
  haw: { label: "Hawaiian", tts: false, flag: "🏝️", native: "Ōlelo Hawaiʻi" },
  he: { label: "Hebrew", country: "il", native: "עִברִית", multiLangTts: true, ytLang: "iw" },
  hi: { label: "Hindi", country: "in", native: "हिन्दी", multiLangTts: true },
  hmn: { label: "Hmong", tts: false, country: "cn", native: "Hmong" },
  hu: { label: "Hungarian", native: "Magyar", aiSupport: true, multiLangTts: true },
  is: { label: "Icelandic", native: "Íslensku", multiLangTts: true },
  ig: { label: "Igbo", tts: false, country: "ng", native: "Ndi Igbo" },
  id: { label: "Indonesian", native: "bahasa Indonesia", aiSupport: true, multiLangTts: true },
  ga: { label: "Irish", country: "ie", native: "Gaeilge", aiSupport: true },
  it: { label: "Italian", native: "italiano", aiSupport: true, multiLangTts: true },
  ja: { label: "Japanese", country: "jp", native: "日本人", aiSupport: true, multiLangTts: true },
  jv: { label: "Javanese", country: "id", native: "Basa jawa" },
  kn: { label: "Kannada", country: "in", native: "ಕನ್ನಡ", multiLangTts: true },
  kk: { label: "Kazakh", country: "kz", native: "Қазақ", multiLangTts: true },
  km: { label: "Khmer", country: "kh", native: "ខ្មែរ" },
  rw: { label: "Kinyarwanda", tts: false, native: "Kinyarwanda" },
  ko: { label: "Korean", country: "kr", native: "한국어", aiSupport: true, multiLangTts: true },
  ku: { label: "Kurdish", tts: false, flagPng: "https://cdn.duocards.com/flags/kurdistan.png", native: "Kurdî" },
  ky: { label: "Kyrgyz", tts: false, country: "kg", native: "Кыргызча" },
  lo: { label: "Lao", country: "la", native: "ລາວ" },
  la: { label: "Latin", tts: false, flag: "🇻🇦", native: "Latine" },
  lv: { label: "Latvian", native: "Latvietis", aiSupport: true, multiLangTts: true },
  lt: { label: "Lithuanian", native: "Lietuvis", aiSupport: true, multiLangTts: true },
  lb: { label: "Luxembourgish", tts: false, country: "lu", native: "Lëtzebuergesch" },
  mk: { label: "Macedonian", native: "Македонски", aiSupport: true, multiLangTts: true },
  mg: { label: "Malagasy", tts: false, native: "Malagasy" },
  ms: { label: "Malay", country: "my", native: "Bahasa Melayu", multiLangTts: true },
  ml: { label: "Malayalam", country: "in", native: "മലയാളം" },
  mt: { label: "Maltese", native: "Malti" },
  mi: { label: "Maori", tts: false, country: "nz", native: "Maori", multiLangTts: true },
  mr: { label: "Marathi", country: "in", native: "मराठी", multiLangTts: true },
  mn: { label: "Mongolian", native: "Монгол" },
  my: { label: "Myanmar (Burmese)", country: "mm", native: "မြန်မာ (မြန်မာ)" },
  ne: { label: "Nepali", country: "np", native: "नेपाली", multiLangTts: true },
  no: { label: "Norwegian", ttsCode: "nb", native: "norsk", aiSupport: true, multiLangTts: true },
  ny: { label: "Nyanja (Chichewa)", tts: false, country: "mw", native: "Nyanja (Chichewa)" },
  or: { label: "Odia (Oriya)", native: "ଓଡିଆ", flagPng: "https://cdn.duocards.com/flags/odia.png" },
  ps: { label: "Pashto", country: "af", native: "پښتو" },
  fa: { label: "Persian", country: "ir", native: "فارسی", multiLangTts: true },
  pl: { label: "Polish", native: "Polskie", aiSupport: true, multiLangTts: true },
  pt: {
    label: "Portuguese",
    flagPng: "https://cdn.duocards.com/flags/pt.png",
    native: "Português",
    aiSupport: true,
    multiLangTts: true
  },
  pa: { label: "Punjabi", country: "pk", native: "ਪੰਜਾਬੀ" },
  ro: { label: "Romanian", native: "Română", multiLangTts: true },
  ru: { label: "Russian", native: "русский", aiSupport: true, multiLangTts: true },
  sm: { label: "Samoan", tts: false, country: "ws", native: "Sāmoa" },
  gd: { label: "Scots Gaelic", tts: false, flag: "🥃", native: "Gàidhlig na h-Alba" },
  sr: { label: "Serbian", country: "rs", native: "Српски", aiSupport: true, multiLangTts: true },
  st: { label: "Sesotho", tts: false, country: "ls", native: "Sesotho" },
  sn: { label: "Shona", tts: false, country: "zw", native: "Shona" },
  sd: { label: "Sindhi", tts: false, country: "pk", native: "سنڌي" },
  si: { label: "Sinhala (Sinhalese)", country: "lk", native: "සිංහල (සිංහල)" },
  sk: { label: "Slovak", native: "slovenský", aiSupport: true, multiLangTts: true },
  sl: { label: "Slovenian", country: "si", aiSupport: true, native: "Slovenščina", multiLangTts: true },
  so: { label: "Somali", native: "Soomaali" },
  es: { label: "Spanish", native: "Español", aiSupport: true, multiLangTts: true },
  su: { label: "Sundanese", country: "id", native: "Urang Sunda" },
  sw: { label: "Swahili", country: "ke", native: "Kiswahili", multiLangTts: true },
  sv: { label: "Swedish", country: "se", native: "svenska", aiSupport: true },
  tl: { label: "Filipino (Tagalog)", ttsCode: "fil", country: "ph", native: "Philippines" },
  tg: { label: "Tajik", tts: false, country: "tj", native: "Точик" },
  ta: { label: "Tamil", country: "lk", native: "தமிழ்" },
  tt: { label: "Tatar", tts: false, native: "Татар", flagPng: "https://cdn.duocards.com/flags/tatar.png" },
  te: { label: "Telugu", country: "in", native: "తెలుగు" },
  th: { label: "Thai", native: "ไทย" },
  tr: { label: "Turkish", native: "Türk", aiSupport: true, multiLangTts: true },
  tk: { label: "Turkmen", tts: false, native: "Türkmenler", country: "tm" },
  uk: { label: "Ukrainian", country: "ua", native: "Українська", aiSupport: true, multiLangTts: true },
  ur: { label: "Urdu", country: "pk", native: "اردو", multiLangTts: true },
  ug: { label: "Uyghur", tts: false, native: "ئۇيغۇرچە", flagPng: "https://cdn.duocards.com/flags/uyghur.png" },
  uz: { label: "Uzbek", native: "O'zbek" },
  vi: { label: "Vietnamese", country: "vn", native: "Tiếng Việt", aiSupport: true, multiLangTts: true },
  cy: { label: "Welsh", country: "gb-wls", native: "Cymraeg", multiLangTts: true },
  xh: { label: "Xhosa", tts: false, country: "za", native: "isiXhosa" },
  yi: { label: "Yiddish", tts: false, flag: "🕍", native: "יידיש" },
  yo: { label: "Yoruba", tts: false, country: "ng", native: "Yoruba" },
  zu: { label: "Zulu", country: "za", native: "IsiZulu" }
};

export const topAppLangs = ["en", "es", "fr", "de"];

export const appLangs = ["en", "es", "de", "fr", "ru", "pt", "ko", "ja", "it", "cs", "sk", "hr", "uz", "uk"];

export const topLangs = ["en", "es", "fr", "de", "pt", "it"];

export const smallAlphabets = [
  "ar",
  "th",
  "he",
  "ps",
  "fa",
  "sd",
  "si",
  "ta",
  "te",
  "ur",
  "yi",
  "hi",
  "zh",
  "ja",
  "ko",
  "or"
];

export const rightAlignLangs = ["ar", "he", "ps", "fa", "ur", "sd"];

export const isAppLangGenerated = (lang: string): boolean => {
  return !appLangs.includes(lang);
};
