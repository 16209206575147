/**
 * @generated SignedSource<<7c370738aa8657bfa1820d236a180b32>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type passwordMutation$variables = {
  hash?: string | null;
  newPassword?: string | null;
};
export type passwordMutation$data = {
  readonly password: {
    readonly token: string;
  };
};
export type passwordMutation = {
  response: passwordMutation$data;
  variables: passwordMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hash"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "newPassword"
},
v2 = [
  {
    "kind": "Variable",
    "name": "hash",
    "variableName": "hash"
  },
  {
    "kind": "Variable",
    "name": "newPassword",
    "variableName": "newPassword"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "token",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "passwordMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "password",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "passwordMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "password",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ed53c7135cc7fa99be3f32b361014662",
    "id": null,
    "metadata": {},
    "name": "passwordMutation",
    "operationKind": "mutation",
    "text": "mutation passwordMutation(\n  $newPassword: String\n  $hash: String\n) {\n  password(newPassword: $newPassword, hash: $hash) {\n    token\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "f57ddc50e9d30eb5fcc62b991566495f";

export default node;
