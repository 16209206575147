/**
 * @generated SignedSource<<05860654792d9d091b66ec562bd179c3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type generateGrammarMutation$variables = {
  fromLang: string;
  text: string;
  toLang: string;
};
export type generateGrammarMutation$data = {
  readonly generateGrammar: {
    readonly examples: ReadonlyArray<string>;
    readonly grammar: ReadonlyArray<{
      readonly grammar: string;
      readonly id: string;
      readonly lang: string;
    }> | null;
    readonly text: string | null;
  } | null;
};
export type generateGrammarMutation = {
  response: generateGrammarMutation$data;
  variables: generateGrammarMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fromLang"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "text"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "toLang"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "fromLang",
        "variableName": "fromLang"
      },
      {
        "kind": "Variable",
        "name": "text",
        "variableName": "text"
      },
      {
        "kind": "Variable",
        "name": "toLang",
        "variableName": "toLang"
      }
    ],
    "concreteType": "DictionaryType",
    "kind": "LinkedField",
    "name": "generateGrammar",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "text",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "examples",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "GrammarReturnType",
        "kind": "LinkedField",
        "name": "grammar",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lang",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "grammar",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "generateGrammarMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "generateGrammarMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "4cc718b974fdfe4a798ea305110dbd30",
    "id": null,
    "metadata": {},
    "name": "generateGrammarMutation",
    "operationKind": "mutation",
    "text": "mutation generateGrammarMutation(\n  $text: String!\n  $fromLang: String!\n  $toLang: String!\n) {\n  generateGrammar(text: $text, fromLang: $fromLang, toLang: $toLang) {\n    text\n    examples\n    grammar {\n      id\n      lang\n      grammar\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6c259aa243bf99171f083bfaa16c30a1";

export default node;
