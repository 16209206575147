import { useEnvironment } from "../graphql/environment";
import { throttle } from "lodash";
import { useCallback } from "react";
import { hashCode, silenceTts, canTts, usePickVoices } from "./tts/ttsTools";
import { useGetState, useSetState } from "../components/ReduxProvider";
import { fetchTts } from "./tts/fetchTts";
import { MULTILINGUAL_LANG_CODE } from "sharedJs__generated/constants";
import { getTheme } from "styled/theme";
import { Howl } from "howler";
import { sentryCapture } from "sentry/sentry";
import { howlerCache } from "./tts/howlerCache";

export const TOO_LONG = 200;

type Args = {
  text: string;
  speakingRate?: number;
  voiceId?: string;
  hash?: string;
  onStarted?: VoidFunction;
  onEnded?: VoidFunction;
  useServerCache?: boolean;
};

const addHowlListeners = (
  sound: Howl,
  { onStarted, onEnded }: { onStarted?: VoidFunction; onEnded?: VoidFunction }
) => {
  sound.on("play", () => onStarted?.());
  sound.on("end", () => onEnded?.());
  sound.on("playerror", () => onEnded?.());
  sound.on("stop", () => onEnded?.());
  sound.on("loaderror", () => onEnded?.());
};

const removeHowlListeners = (sound: Howl) => {
  sound.off("play");
  sound.off("end");
  sound.off("playerror");
  sound.off("stop");
  sound.off("loaderror");
};

export const useTtsQuery = (language?: string, additionalLangs?: string[]) => {
  const environment = useEnvironment();
  const lastVoice = useGetState("lastVoice");
  const setLastVoice = useSetState("lastVoice");
  const cardsData = useGetState("idbCardsData");

  const lang = additionalLangs ? MULTILINGUAL_LANG_CODE : language;

  const sortedVoices = usePickVoices(lang, lastVoice?.voiceId);
  const firstVoice = sortedVoices?.[0];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const ttsQuery = useCallback(
    throttle(
      ({ text, speakingRate, voiceId: voiceIdParam, onEnded, onStarted, useServerCache = true }: Args) => {
        const voiceId = voiceIdParam ? voiceIdParam : firstVoice;
        const speakUp = (base64: string) => {
          voiceId && setLastVoice({ voiceId, text });
          silenceTts();

          const src = "data:audio/wav;base64," + base64;
          try {
            const cacheKey = hashCode(text, voiceId) + (speakingRate ? speakingRate.toString() : "");
            let sound = howlerCache.get(cacheKey);
            if (sound) {
              removeHowlListeners(sound);
            } else {
              sound = new Howl({
                src: [src],
                format: ["wav"],
                html5: true
              });
              howlerCache.add(cacheKey, sound);
            }
            addHowlListeners(sound, { onStarted, onEnded });
            sound.play();
          } catch (e) {
            onEnded?.();
            sentryCapture("Error playing sound: ", { e });
          }

          const toHighlight = hashCode(text, voiceIdParam);
          const els = document.getElementsByClassName(toHighlight);
          for (let el of els as any) {
            el.style.color = getTheme().color.primary;
            setTimeout(() => el && el.style.removeProperty("color"), 1000);
          }
        };

        const cached =
          cardsData &&
          !speakingRate &&
          cardsData.find(
            (cd) => cd.text === text && cd.lang === lang && (voiceId === cd.ttsVoiceId || lastVoice?.text !== text)
          );

        if (cached) {
          cached.tts && speakUp(cached.tts);
        } else {
          fetchTts(environment, { lang: lang || "", text, speakingRate, voiceId, useCache: useServerCache })
            .then((data) => {
              if (!data?.speech) {
                onEnded?.();
                return;
              }
              speakUp(data.speech);
            })
            .catch((e) => {
              onEnded?.();
              throw e;
            });
        }
      },
      1000,
      { trailing: false }
    ),
    [firstVoice, cardsData]
  );

  return canTts(language, additionalLangs) && cardsData ? ttsQuery : null;
};
