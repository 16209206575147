import React from "react";
import { Trans } from "react-i18next";
import styled, { css } from "styled-components/macro";
import { Button, Slide } from "@mui/material";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import DialogFullWindow from "components/other/DialogFullWindow";
import { useSetFlag } from "../../../queries/users/setFlag";
import { useViewerQuery } from "../../../queries/viewerQuery";
import { useEffectOnce, useEvent } from "react-use";
import { EVENT } from "../../../tools/events";
import { subDays } from "date-fns";
import { GOAL_FLAG_NAME, NEW_YEAR_END_DATE, NEW_YEAR_START_DATE } from "../../../sharedJs__generated/constants";

const Wrap = styled.div`
  display: flex;
  min-height: 100%;
  flex-direction: column;
  justify-content: space-around;
  padding: 40px 25px 30px;
`;

const Title = styled.div`
  font-weight: ${({ theme }) => theme.duo.fontWeight.w700};
  font-size: 120%;
  margin: 10px 20px 5px;
  text-align: center;
`;
const List = styled.div`
  box-shadow: 0 0 16px #def;
  margin: 20px -15px;
  svg {
    margin: 0 10px 0 0;
  }
`;
const SImg = styled.img`
  padding: 0 20px 20px;

  @media (min-width: ${({ theme }) => theme.duo.maxWidth.normal}) {
    max-width: 500px;
    margin: 0 auto;
  }
`;
const Row = styled.div<{ selected?: boolean }>`
  border: 1px solid white;
  padding: 17px 20px;
  border-top: 1px solid ${({ theme }) => theme.duo.color.grey};
  display: flex;
  align-items: center;
  font-size: 15px;
  cursor: pointer;
  &:first-of-type {
    border-top: 1px solid white;
  }
  svg {
    font-size: 20px;
  }
  ${({ selected }) =>
    selected &&
    css`
      color: ${({ theme }) => theme.duo.color.primary};
      border: 1px solid #0099dd !important;
      border-radius: 4px;
    `}
`;
const ButtonWrap = styled.div`
  padding: 5px 30px;
`;
const TAC = styled.p`
  text-align: center;
`;

type Props = {};

const GoalDialog: React.FC<Props> = () => {
  const [goal, setGoal] = React.useState("");
  const setFlag = useSetFlag();
  const { getFlag, viewer } = useViewerQuery();
  const [open, setOpen] = React.useState(false);
  const [template, setTemplate] = React.useState<"default" | "newYearResolution">("default");

  useEvent(EVENT.openGoalDialog, () => {
    // at most open the goal dialog once per 3 days
    const goalFlag = getFlag("myGoal");
    if (new Date(goalFlag?.updated || 0) < subDays(new Date(), 3)) {
      setOpen(true);
      setTemplate("default");
    }
  });

  useEffectOnce(() => {
    // once a year it shows new year resolution goal
    const lastGoalTime = getFlag(GOAL_FLAG_NAME)?.updated || 0;
    const canShow = new Date() >= NEW_YEAR_START_DATE && new Date() < NEW_YEAR_END_DATE;
    const wasShown = new Date(lastGoalTime) >= NEW_YEAR_START_DATE && new Date(lastGoalTime) < NEW_YEAR_END_DATE;

    if (canShow && !wasShown && Number(viewer?.createdBeforeDays) > 7) {
      setOpen(true);
      setTemplate("newYearResolution");
    }
  });

  const handleSave = () => {
    setFlag({ name: "myGoal", value: goal });
    setOpen(false);
  };

  let imgSrc = "https://cdn.duocards.com/img/goal.png";
  let titles = [
    { value: "3", text: "I will learn 3 days in a row" },
    { value: "7", text: "I will learn 7 days in a row" },
    { value: "14", text: "I will learn 14 days in a row" },
    { value: "0", text: "I don't want any goals" }
  ];
  if (template === "newYearResolution") {
    imgSrc = "https://cdn.duocards.com/img/memo-new-year.png";
    titles = [
      { value: "30", text: "I will learn 30 days in a row" },
      { value: "60", text: "I will learn 60 days in a row" },
      { value: "365", text: "I will learn 1 year in a row" },
      { value: "0", text: "I don't want any goals" }
    ];
  }

  return (
    <DialogFullWindow open={open} TransitionComponent={Slide}>
      <Wrap>
        <div />
        <SImg src={imgSrc} alt="Goal" className="nofilter" />

        {template === "newYearResolution" && (
          <>
            <Title>
              <Trans>Lets make this year your best year!</Trans>
            </Title>
            <TAC>
              <Trans>Commit yourself to learning journey and make your language goals a reality!</Trans>
            </TAC>
          </>
        )}
        {template !== "newYearResolution" && (
          <>
            <Title>
              <Trans>Set a goal for yourself</Trans>
            </Title>
          </>
        )}

        <List>
          {titles.map(({ value, text }) => (
            <Row key={value} selected={value === goal} onClick={() => setGoal(value)}>
              {value === goal ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
              <Trans>{text}</Trans>
            </Row>
          ))}
        </List>

        <ButtonWrap>
          <Button
            disabled={goal === ""}
            color="primary"
            variant="contained"
            size="large"
            fullWidth
            onClick={handleSave}
          >
            <Trans>Continue</Trans>
          </Button>
        </ButtonWrap>
        <div />
      </Wrap>
    </DialogFullWindow>
  );
};

export default GoalDialog;
