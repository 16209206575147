import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import RowToggleItem, { ItemData } from "./ToggleItem";

export type SelectItem = ItemData;

export enum OptionVisualType {
  GRID = "grid",
  LIST = "list"
}

type SelectListProps = {
  items: SelectItem[];
  onChange: (selectedItems: SelectItem[]) => void;
  layoutMode?: OptionVisualType;
  itemStyleType?: "rounded" | "square";
  multiSelect?: boolean;
  initialSelectedIds?: string[];
};

const BaseContainer = styled.div`
  position: relative;
  padding: 40px 30px 0px 30px;
`;

const ListLayout = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const GridLayout = css`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  @media (min-width: 500px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const Container = styled(BaseContainer)<{ $layoutMode: OptionVisualType }>`
  ${({ $layoutMode }) => ($layoutMode === "grid" ? GridLayout : ListLayout)}
`;

const GradientOverlay = styled.div`
  content: "";
  order: 1;
  position: sticky;
  bottom: -5px;
  width: 102%;
  margin: 0 -1%;
  height: 40px;
  background: linear-gradient(to top, ${({ theme }) => theme.duo.color.lightGrey} 5%, transparent);
  pointer-events: none;
  grid-column: 1 / -1;
`;

const SelectList: React.FC<SelectListProps> = ({
  items,
  onChange,
  layoutMode = OptionVisualType.LIST,
  multiSelect = false,
  initialSelectedIds = []
}) => {
  const [selectedItems, setSelectedItems] = useState<SelectItem[]>([]);

  useEffect(() => {
    const initialSelected = items.filter((item) => initialSelectedIds.includes(item.id));
    setSelectedItems(initialSelected);
  }, [initialSelectedIds, items]);

  const handleToggle = (item: SelectItem, toggled: boolean) => {
    const updatedSelectedItems = multiSelect
      ? toggled
        ? [...selectedItems, item]
        : selectedItems.filter((selectedItem) => selectedItem.id !== item.id)
      : toggled
        ? [item]
        : selectedItems; // allways select something
    setSelectedItems(updatedSelectedItems);
    onChange(updatedSelectedItems);
  };

  return (
    <Container $layoutMode={layoutMode}>
      {items.map((item) => (
        <RowToggleItem
          key={item.id}
          id={item.id}
          icon={item.icon}
          text={item.text}
          translate={item.translate === undefined ? true : item.translate} // default use translation
          extraContent={item.extraContent}
          toggle={selectedItems.some((selectedItem) => selectedItem.id === item.id)}
          onToggle={(toggled) => handleToggle(item, toggled)}
          itemStyleType={layoutMode === "list" ? "rounded" : "square"}
        />
      ))}
      <GradientOverlay />
    </Container>
  );
};

export default SelectList;
