import { useCallback, useRef } from "react";

const DEFAULT_HOLD_THRESHOLD_MS = 600;

type UseTapOrHoldProps = {
  /** Callback fired when press/hold action begins */
  startAction?: () => void;

  /** Callback fired when press/hold action ends */
  endAction?: () => void;

  /** Time in milliseconds to distinguish between a tap vs hold */
  holdThresholdMs?: number;

  /** Disable the tap/hold action */
  disabled?: boolean;
};

const useTapOrHold = ({
  startAction,
  endAction,
  holdThresholdMs = DEFAULT_HOLD_THRESHOLD_MS,
  disabled
}: UseTapOrHoldProps) => {
  const isPressedRef = useRef(false);
  const pressStartTimeRef = useRef<number>(0);
  const isPointerEventsSupported = typeof window !== "undefined" && window.PointerEvent !== undefined;

  const resetPressState = useCallback(() => {
    isPressedRef.current = false;
    pressStartTimeRef.current = 0;
  }, []);

  const handlePressStart = useCallback(
    (e: React.PointerEvent | React.MouseEvent) => {
      if (disabled) return;
      e.preventDefault();
      pressStartTimeRef.current = Date.now();

      if (isPressedRef.current) {
        isPressedRef.current = false;
        endAction?.();
      } else {
        isPressedRef.current = true;
        startAction?.();
      }
    },
    [startAction, endAction, isPressedRef, disabled]
  );

  const handlePressEnd = useCallback(
    (e: React.PointerEvent) => {
      if (disabled) return;
      e.preventDefault();
      const pressDuration = Date.now() - pressStartTimeRef.current;
      const wasQuickTap = pressDuration < holdThresholdMs;
      if (!wasQuickTap) {
        isPressedRef.current = false;
        endAction?.();
      }
    },
    [endAction, holdThresholdMs, disabled]
  );

  return {
    handlers: isPointerEventsSupported
      ? {
          onPointerDown: handlePressStart,
          onPointerUp: handlePressEnd
        }
      : {
          onClick: handlePressStart
        },
    resetPressState
  };
};

export default useTapOrHold;
