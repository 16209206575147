import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import { logoutMutation } from "queries/users/__generated__/logoutMutation.graphql";
import { logout, useSetState } from "components/ReduxProvider";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { signOut } from "firebase/auth";
import { clearIndexDB } from "dexieDB/dexie";
import { isCordovaGooglePluginEnabled } from "../../tools/device";

export const mutation = graphql`
  mutation logoutMutation {
    logout
  }
`;

export const useLogout = () => {
  const [logoutOnBE] = useMutation<logoutMutation>(mutation);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const setLoaderGlobal = useSetState("loaderGlobal");

  return async (to: string | null = "/") => {
    setLoaderGlobal(true);
    await logoutOnBE({ variables: {} });
    await signOut((window as any).firebaseAuth);
    clearIndexDB();
    dispatch(logout());
    setLoaderGlobal(false);
    if (isCordovaGooglePluginEnabled()) {
      // cordova plugin - needs to be logged out, otherwise it will remember last google account
      window.plugins.googleplus.logout();
    }
    if (to) navigate(to);
  };
};
