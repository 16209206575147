import { SelectItem, OptionVisualType } from "./SelectList";
import ListItemIcon from "./ListItemIcon";
import styled from "styled-components";
import { DifficultyEnum, NotificationEnum } from "sharedJs__generated/constants";
import { isAndroid, isIos } from "tools/device";
import NotificationOption from "./NotificationOption";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";

const LangLevel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #808080;
`;

export type QuestionData = {
  id: QuestionType;
  visualType: OptionVisualType;
  multipleAnswers: boolean;
  options: SelectItem[];
};

type QuestionsData = {
  [key in QuestionType]: QuestionData;
};

export enum QuestionType {
  LEVEL = "level",
  NOTIFICATIONS = "notifications",
  INTERESTS = "interests",
  AGE = "age",
  REFERRALS = "referrals",
  MOTIVATION = "motivation"
}

const getReferralsOptions = () => {
  let options = [
    {
      text: "Friends/Family",
      id: "friends_family",
      icon: <ListItemIcon url="https://imgs.duocards.com/onboarding/ref/friends.svg" />
    },
    {
      text: "Google Play",
      id: "google_play",
      translate: false,
      icon: <ListItemIcon url="https://imgs.duocards.com/onboarding/ref/googleplay.svg" />
    },
    {
      text: "App Store",
      id: "app_store",
      icon: <ListItemIcon url="https://imgs.duocards.com/onboarding/ref/apple.svg" />,
      translate: false
    },
    {
      text: "School",
      id: "school",
      icon: <ListItemIcon url="https://imgs.duocards.com/onboarding/intrs/education.svg" />
    },
    {
      text: "YouTube",
      id: "youtube",
      translate: false,
      icon: <ListItemIcon url="https://imgs.duocards.com/onboarding/ref/youtube.svg" />
    },
    {
      text: "Google Search",
      id: "google_search",
      icon: <ListItemIcon url="https://imgs.duocards.com/onboarding/ref/googlesearch.svg" />
    },
    {
      text: "FB / Insta",
      id: "meta",
      translate: false,
      icon: (
        <div style={{ position: "relative", width: "30px", height: "30px" }}>
          <ListItemIcon
            url="https://imgs.duocards.com/onboarding/ref/instagram.svg"
            style={{ position: "absolute", top: "0", left: "0%", transform: "translate(-35%, 0%)" }}
          />
          <ListItemIcon
            url="https://imgs.duocards.com/onboarding/ref/facebook.svg"
            style={{ position: "absolute", top: "0%", left: "0%", transform: "translate(35%, 0%)" }}
          />
        </div>
      )
    },
    {
      text: "TikTok",
      id: "tiktok",
      translate: false,
      icon: <ListItemIcon url="https://imgs.duocards.com/onboarding/ref/tiktok.svg" />
    },
    {
      text: "Other",
      id: "other",
      icon: <ListItemIcon url="https://imgs.duocards.com/onboarding/ref/other.svg" />
    }
  ];

  if (!isAndroid()) {
    // remove google play
    options = options.filter((option) => option.id !== "google_play");
  }

  if (!isIos()) {
    // remove app store
    options = options.filter((option) => option.id !== "app_store");
  }

  return options;
};

export const questionsData: QuestionsData = {
  [QuestionType.LEVEL]: {
    id: QuestionType.LEVEL,
    visualType: OptionVisualType.LIST,
    multipleAnswers: false,
    options: [
      {
        text: "beginner",
        id: DifficultyEnum.beginner,
        icon: <ListItemIcon url="https://imgs.duocards.com/onboarding/level/1.svg" />,
        extraContent: <LangLevel>A1</LangLevel>
      },
      {
        text: "easy",
        id: DifficultyEnum.easy,
        icon: <ListItemIcon url="https://imgs.duocards.com/onboarding/level/2.svg" />,
        extraContent: <LangLevel>A2</LangLevel>
      },
      {
        text: "moderate",
        id: DifficultyEnum.moderate,
        icon: <ListItemIcon url="https://imgs.duocards.com/onboarding/level/3.svg" />,
        extraContent: <LangLevel>B</LangLevel>
      },
      {
        text: "hard",
        id: DifficultyEnum.hard,
        icon: <ListItemIcon url="https://imgs.duocards.com/onboarding/level/4.svg" />,
        extraContent: <LangLevel>C</LangLevel>
      }
    ]
  },
  [QuestionType.NOTIFICATIONS]: {
    id: QuestionType.NOTIFICATIONS,
    visualType: OptionVisualType.LIST,
    multipleAnswers: false,
    options: [
      {
        text: <NotificationOption text="Not sure" description="Ask me later" />,
        id: NotificationEnum.none + "",
        icon: <NotificationsOffIcon />,
        translate: false
      },
      {
        text: <NotificationOption text="Not much" description="Notify me after 2 days of inactivity" />,
        id: NotificationEnum.easy + "",
        icon: <NotificationsNoneIcon />,
        translate: false
      },
      {
        text: <NotificationOption text="Regularly" description="Notify me after 1 day of inactivity" />,
        id: NotificationEnum.normal + "",
        icon: <NotificationsIcon />,
        translate: false
      },
      {
        text: (
          <NotificationOption text="Very hard" description="Notify me after 4 hours if there are cards to learn." />
        ),
        id: NotificationEnum.hard + "",
        icon: <NotificationImportantIcon />,
        translate: false
      }
    ]
  },
  [QuestionType.MOTIVATION]: {
    id: QuestionType.MOTIVATION,
    visualType: OptionVisualType.LIST,
    multipleAnswers: false,
    options: [
      {
        text: "Traveling",
        id: "traveling",
        icon: <ListItemIcon url="https://imgs.duocards.com/onboarding/goal/traveling.svg" />
      },
      {
        text: "Education",
        id: "education",
        icon: <ListItemIcon url="https://imgs.duocards.com/onboarding/goal/study.svg" />
      },
      {
        text: "Work",
        id: "work",
        icon: <ListItemIcon url="https://imgs.duocards.com/onboarding/goal/job.svg" />
      },
      {
        text: "Hobby",
        id: "hobby",
        icon: <ListItemIcon url="https://imgs.duocards.com/onboarding/goal/hobby.svg" />
      },
      {
        text: "Other",
        id: "other",
        icon: <ListItemIcon url="https://imgs.duocards.com/onboarding/goal/another.svg" />
      }
    ]
  },
  [QuestionType.INTERESTS]: {
    id: QuestionType.INTERESTS,
    visualType: OptionVisualType.GRID,
    multipleAnswers: true,
    options: [
      {
        text: "Education",
        id: "education",
        icon: <ListItemIcon url="https://imgs.duocards.com/onboarding/intrs/education.svg" />
      },
      {
        text: "Travel",
        id: "travel",
        icon: <ListItemIcon url="https://imgs.duocards.com/onboarding/intrs/travel.svg" />
      },
      {
        text: "Arts",
        id: "art",
        icon: <ListItemIcon url="https://imgs.duocards.com/onboarding/intrs/art.svg" />
      },
      {
        text: "Books",
        id: "books",
        icon: <ListItemIcon url="https://imgs.duocards.com/onboarding/intrs/books.svg" />
      },
      {
        text: "Business",
        id: "business",
        icon: <ListItemIcon url="https://imgs.duocards.com/onboarding/intrs/business.svg" />
      },
      {
        text: "Career",
        id: "career",
        icon: <ListItemIcon url="https://imgs.duocards.com/onboarding/intrs/career.svg" />
      },
      {
        text: "Cooking",
        id: "cooking",
        icon: <ListItemIcon url="https://imgs.duocards.com/onboarding/intrs/cooking.svg" />
      },
      {
        text: "Food",
        id: "food",
        icon: <ListItemIcon url="https://imgs.duocards.com/onboarding/intrs/food.svg" />
      },
      {
        text: "Mindfulness",
        id: "mindfulness",
        icon: <ListItemIcon url="https://imgs.duocards.com/onboarding/intrs/mindfulness.svg" />
      },
      {
        text: "Movies",
        id: "movies",
        icon: <ListItemIcon url="https://imgs.duocards.com/onboarding/intrs/movies.svg" />
      },
      {
        text: "Music",
        id: "music",
        icon: <ListItemIcon url="https://imgs.duocards.com/onboarding/intrs/music.svg" />
      },
      {
        text: "Nature",
        id: "nature",
        icon: <ListItemIcon url="https://imgs.duocards.com/onboarding/intrs/nature.svg" />
      },
      {
        text: "News",
        id: "news",
        icon: <ListItemIcon url="https://imgs.duocards.com/onboarding/intrs/news.svg" />
      },
      {
        text: "Pets",
        id: "pets",
        icon: <ListItemIcon url="https://imgs.duocards.com/onboarding/intrs/pets.svg" />
      },
      {
        text: "Philosophy",
        id: "philosophy",
        icon: <ListItemIcon url="https://imgs.duocards.com/onboarding/intrs/philosophy.svg" />
      },
      {
        text: "Science",
        id: "science",
        icon: <ListItemIcon url="https://imgs.duocards.com/onboarding/intrs/science.svg" />
      },
      {
        text: "Sport",
        id: "sport",
        icon: <ListItemIcon url="https://imgs.duocards.com/onboarding/intrs/sport.svg" />
      },
      {
        text: "Technology",
        id: "technology",
        icon: <ListItemIcon url="https://imgs.duocards.com/onboarding/intrs/technology.svg" />
      }
    ]
  },
  [QuestionType.AGE]: {
    id: QuestionType.AGE,
    visualType: OptionVisualType.LIST,
    multipleAnswers: false,
    options: [
      { text: "Under 12", id: "under-12" },
      { text: "12-15", id: "12-15", translate: false },
      { text: "16-24", id: "16-24", translate: false },
      { text: "25-34", id: "25-34", translate: false },
      { text: "35-44", id: "35-44", translate: false },
      { text: "45-54", id: "45-54", translate: false },
      { text: "55-64", id: "55-64", translate: false },
      { text: "65 or older", id: "65+" },
      { text: "I prefer not to share", id: "prefer-not-to-share" }
    ]
  },
  [QuestionType.REFERRALS]: {
    id: QuestionType.REFERRALS,
    visualType: OptionVisualType.GRID,
    multipleAnswers: false,
    options: getReferralsOptions()
  }
};
