import { ONBOARDING_DATA_FLAG } from "queries/users/userFlagsModel";
import useJsonFlag from "./useJsonFlag";
import { PreparedSources } from "root/tutorial/onboarding/onboardingTypes";
import { StepScreenId } from "root/tutorial/onboarding/onboardingTypes";

type OnboardingData = {
  currentStep: StepScreenId | undefined;
  latestStepReached: StepScreenId | undefined;
  preparedSources: PreparedSources;
};

const DEFAULT_ONBOARDING_DATA: OnboardingData = {
  currentStep: undefined,
  latestStepReached: undefined,
  preparedSources: {
    setOrCourseId: undefined,
    videoId: undefined,
    articleId: undefined
  }
};

const useOnboardingData = () => {
  const { getFlagData, saveFlagData } = useJsonFlag<OnboardingData>(ONBOARDING_DATA_FLAG, DEFAULT_ONBOARDING_DATA);

  return {
    saveOnboardingData: saveFlagData,
    getOnboardingData: getFlagData
  };
};

export default useOnboardingData;
