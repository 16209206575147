import React from "react";
import Loader from "../other/Loader";
import styled from "styled-components/macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone } from "@fortawesome/pro-regular-svg-icons";
import { getTheme } from "styled/theme";
import useSpeechTapHold from "hooks/useSpeechTapHold";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin-right: 5px;
  touch-action: none;
  user-select: none;
`;

const RecordButton = styled.button<{ disabled: boolean | undefined }>`
  color: ${({ disabled, theme }) => (disabled ? theme.duo.palette.greyLight : theme.duo.palette.greyDark)};
  border: none;
  transition: color 0.3s;
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  width: 34px;
`;

const SFontAwesomeIcon = styled(FontAwesomeIcon)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
`;

interface SpeechToTextProps {
  onTranscription: (text: string) => void;
  languages?: string[]; // specify if you know the language
}

const SpeechToText: React.FC<SpeechToTextProps> = ({ onTranscription, languages }) => {
  const { transcribing, listening, supported, handlers, globalRecording, otherRecording } = useSpeechTapHold({
    onTranscription,
    options: {
      languages,
      stopAfterMs: 30000
    }
  });

  if (!supported) {
    return null;
  }

  return (
    <Wrap>
      {transcribing ? (
        <Loader size={28} />
      ) : (
        <RecordButton {...handlers} disabled={otherRecording}>
          <SFontAwesomeIcon
            icon={faMicrophone}
            color={
              globalRecording
                ? listening
                  ? getTheme().palette.blueMain
                  : getTheme().palette.greyLight
                : getTheme().palette.greyDark
            }
            fade={globalRecording ? listening : false}
          />
        </RecordButton>
      )}
    </Wrap>
  );
};

export default SpeechToText;
