import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import { useNavigate } from "react-router";
import { passwordMutation } from "./__generated__/passwordMutation.graphql";
import { useTranslation } from "react-i18next";
import { snackbar } from "tools/events";
import { useSetState } from "components/ReduxProvider";
import { MAIN } from "../../root/RootRouter.config";

const mutation = graphql`
  mutation passwordMutation($newPassword: String, $hash: String) {
    password(newPassword: $newPassword, hash: $hash) {
      token
    }
  }
`;

export const usePasswordMutation = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const setToken = useSetState("token");
  const setLoaderGlobal = useSetState("loaderGlobal");
  const setLoggedByHash = useSetState("loggedByHash");

  const [mutate] = useMutation<passwordMutation>(mutation);

  return (variables: passwordMutation["variables"]) => {
    setLoaderGlobal(true);
    mutate({
      variables,
      onCompleted: ({ password }) => {
        if (password) {
          if (variables.newPassword) snackbar(t("Your password was changed."));
          setToken(password.token);
          navigate(MAIN.url());
          setLoggedByHash(true);
          setLoaderGlobal(false);
        }
      },
      onError: () => {
        setLoaderGlobal(false);
        navigate(MAIN.url());
      }
    });
  };
};
