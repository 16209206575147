import { IconButton } from "@mui/material";
import styled from "styled-components/macro";
import { useViewerQuery } from "../../../queries/viewerQuery";
import { devicePaddings, isBrowser, isDesktop } from "../../../tools/device";
import { useNavigate } from "react-router";
import CloseIcon from "@mui/icons-material/Close";
import { useSeeHint } from "components/hint/Hint";
import { FIRSTSUBSCRIPTION } from "root/tutorial/misc/FirstSubscription";
import { useSaleExpiration, useSubscriptions } from "./subscriptionHooks";
import { ManageSubscriptionButton } from "./ManageSubscriptionButton";
import { SubscriptionAlreadyPurchased } from "./SubscriptionAlreadyPurchased";
import { SubscriptionFooter } from "./SubscriptionFooter";
import { Trans } from "react-i18next";
import SubscriptionText from "./SubscriptionText";
import PriceButtons from "./PriceButtons";
import React from "react";
import Famembers from "./Famembers";
import { StoreId, useStoreIds } from "./subscriptionIds";
import SubscribedUntil from "./SubscribedUntil";
import SaleDialog from "./SaleDialog";
import { TUTORIALDONE } from "../../tutorial/steps/Verdict";
import { useSetFlag } from "../../../queries/users/setFlag";
import SubscriptionPromocode from "./SubscriptionPromocode";
import { MOTIVATIONHINT, SALE } from "sharedJs__generated/constants";
import { useGetState } from "components/ReduxProvider";
import { PROFILE } from "root/RootRouter.config";
import SubscriptionBottom from "./SubscriptionBottom";

const { top, bottom } = devicePaddings();

const Wrap = styled.div`
  text-align: center;
  max-width: ${({ theme }) => theme.duo.maxWidth.narrow};
  width: 100%;
  margin-inline: auto;
  padding: 0;
  height: 100%;
`;
const Header = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
`;
const Content = styled.div`
  padding: ${top}px 5px ${93 + bottom}px 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  border-radius: 4px;
  overflow: auto;
  min-height: ${isDesktop() ? "auto" : "110%"};
  height: ${isDesktop() ? "100%" : "auto"};
`;
const Top = styled.div`
  color: #333;
  text-align: center;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.3em;
  letter-spacing: -0.32px;
  margin: 0 20px 20px;
`;
const BlueTop = styled.div`
  color: #09d;
`;
const SIconButton = styled(IconButton)`
  background: white;
  border-radius: 50px;
  z-index: 1;
  svg {
    width: 22px;
    height: 22px;
    color: #bbb;
  }
`;

const SubscriptionScreen = () => {
  const {
    viewer,
    hintWasSeen,
    subscriptionFlags: {
      hasActiveFamilySub,
      isFamilyFather,
      hasActiveFriendsReward,
      isSubscriptionCancelled,
      isAndroidSubscription,
      isIosSubscription
    }
  } = useViewerQuery();
  const navigate = useNavigate();
  const seeHint = useSeeHint();
  const { store } = useSubscriptions();
  const { storeIdYear, storeIdFamily } = useStoreIds();
  const [plan, setPlan] = React.useState<StoreId>(storeIdYear);
  const { subscriptions } = useSubscriptions();
  const timeSpentRef = React.useRef<number>(Date.now());
  const setFlag = useSetFlag();
  const routeHistory = useGetState("routeHistory");
  const { saleId } = useSaleExpiration();

  React.useEffect(() => {
    setPlan(hasActiveFamilySub || saleId === SALE.christmasFamily ? storeIdFamily : storeIdYear);
  }, [hasActiveFamilySub, storeIdFamily, storeIdYear, saleId]);

  const handleCloseClick = () => {
    if (!hintWasSeen(TUTORIALDONE) && hintWasSeen(MOTIVATIONHINT)) {
      setFlag({ name: "myTimeSpentSubscription", value: String(Date.now() - timeSpentRef.current) });
    }
    const previousRoutePath = routeHistory && routeHistory.length > 1 && routeHistory[1].pathname;
    seeHint(FIRSTSUBSCRIPTION);
    if (previousRoutePath) {
      navigate(previousRoutePath, { replace: true });
    } else {
      navigate(PROFILE.url());
    }
  };

  const isBrowserWithMobileSubscription = isBrowser() && (isAndroidSubscription || isIosSubscription);

  return (
    <Wrap>
      <Content>
        <Header>
          <SIconButton onClick={handleCloseClick} color="inherit">
            <CloseIcon />
          </SIconButton>
        </Header>

        <Trans parent={Top}>
          Unlock <BlueTop>Unlimited</BlueTop> Access
        </Trans>

        <SubscribedUntil cancelled={isSubscriptionCancelled} />
        {isFamilyFather ? (
          <Famembers />
        ) : (
          <>
            {subscriptions && <PriceButtons setPlan={setPlan} plan={plan} />}
            <SubscriptionText />
            <SubscriptionPromocode />
            {isBrowserWithMobileSubscription && (
              <Trans>To manage your subscription, please open the app in your mobile device.</Trans>
            )}
          </>
        )}

        {!isFamilyFather && <SubscriptionBottom plan={plan} />}

        <div>
          {!hasActiveFriendsReward && viewer?.subscription && store && !isBrowserWithMobileSubscription && (
            <ManageSubscriptionButton />
          )}
          <SubscriptionAlreadyPurchased />
          <SubscriptionFooter />
        </div>
      </Content>

      <SaleDialog />
    </Wrap>
  );
};

export default SubscriptionScreen;
