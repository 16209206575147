import React from "react";
export const LOADING_MESSAGES = [
  "Opening the dictionary...",
  "Searching for grammar rules...",
  "Analyzing language patterns...",
  "Gathering relevant examples...",
  "Refining explanations...",
  "Finalizing results...",
  "Almost there..."
];

export const useGrammarLoadingMessages = (isLoading?: boolean) => {
  const [loadingMessageIndex, setLoadingMessageIndex] = React.useState(0);

  React.useEffect(() => {
    if (!isLoading) return;

    const interval = setInterval(() => {
      setLoadingMessageIndex((current) => (current + 1 < LOADING_MESSAGES.length ? current + 1 : current));
    }, 5000);

    return () => {
      clearInterval(interval);
      setLoadingMessageIndex(0);
    };
  }, [isLoading]);

  return {
    loadingMessage: LOADING_MESSAGES[loadingMessageIndex],
    loadingMessageIndex
  };
};
