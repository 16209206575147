import { Trans } from "react-i18next";
import DownloadButton from "root/auth/DownloadButton";
import styled from "styled-components";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";
import { useNavigate } from "react-router-dom";
import { getStepHash } from "./OnboardingRedirect";
import { ONBOARDING } from "root/RootRouter.config";
import { isDesktop } from "tools/device";
import { StepScreenId } from "./onboardingTypes";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  width: 100%;
`;
const Inner = styled.div`
  padding: 10px 25px;
  background: white;
  border-radius: 50px;
  width: 100%;
`;
const Instruction = styled.div`
  margin: 2em 0 1em;
  font-size: 110%;
  font-weight: bold;
`;
const MailIcon = styled(MarkEmailUnreadIcon)`
  font-size: 50px;
  color: ${({ theme }) => theme.duo.color.primary};
`;
const StyledA = styled.a`
  text-decoration: none;
  display: block;
`;
const BottomLinks = styled.div`
  color: ${({ theme }) => theme.duo.color.textGrey};
  font-size: 13px;
  margin: 25px;
  span {
    cursor: pointer;
    padding: 0 2px;
  }
`;

const FollowEmailedInstructions = ({ onRequestSubmit }) => {
  const navigate = useNavigate();

  return (
    <Wrap>
      <Inner>
        <Instruction>
          1. <Trans>Download our app</Trans>
        </Instruction>
        <DownloadButton>
          <Trans>Install DuoCards</Trans>
        </DownloadButton>
        <Instruction>
          2. <Trans>Log in using the link in the email we just sent you.</Trans>
        </Instruction>
        <StyledA href="mailto:">
          <MailIcon />
        </StyledA>
        <BottomLinks>
          <span onClick={() => navigate(ONBOARDING.url() + getStepHash(StepScreenId.SET_EMAIL))}>
            <Trans>I did not receive the email</Trans>
          </span>
          {isDesktop() && (
            <>
              {" "}
              •{" "}
              <span onClick={onRequestSubmit}>
                <Trans>Continue in browser</Trans>
              </span>
            </>
          )}
        </BottomLinks>
      </Inner>
    </Wrap>
  );
};

export default FollowEmailedInstructions;
