import { useSourceCardQuery } from "queries/sources/sourceCardQuery";
import { useViewerDecks } from "queries/viewerQuery";
import React from "react";
import SourceCard from "root/library/library/SourceCard";
import styled from "styled-components";
import Loader from "components/other/Loader";
import useOnboardingData from "hooks/useOnboardingData";
import OnboardingRedirect from "./OnboardingRedirect";
import { StepScreenId } from "./onboardingTypes";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  zoom: 1.4;
`;

type Props = {
  type: "video" | "article" | "setOrCourse";
};

const PreparedSource: React.FC<Props> = ({ type }) => {
  const { deck } = useViewerDecks();
  const { getOnboardingData } = useOnboardingData();
  const { setOrCourseId, videoId, articleId } = getOnboardingData().preparedSources;
  const sourceId = type === "video" ? videoId : type === "article" ? articleId : setOrCourseId;
  const { source, error } = useSourceCardQuery(sourceId || "", deck?.back ?? undefined);

  // sources not prepared
  if (sourceId === undefined) return <OnboardingRedirect screenId={StepScreenId.COURSE_PREPARATION} />;

  // source not found or error
  if (!sourceId || error) return <OnboardingRedirect screenId={StepScreenId.START_LEARNING} />;

  return <Wrapper style={{ zoom: 1.4 }}>{source ? <SourceCard source={source} /> : <Loader />}</Wrapper>;
};

export default PreparedSource;
